const Enums = {
    PAGE_IDS: {
        INTRO: "intro",
        QUESTIONS: "questions",
        OUTRO: "outro"
    },
    VALUES_STRINGS: {
        EMPTY: "",
        SPACE: " ",
        COLON: ":",
        ZERO: "0",
        ENGLISH_LABEL: "English",
        AFRIKAANS_LABEL: "Afrikaans",
        ENGLISH_VALUE: "en",
        AFRIKAANS_VALUE: "af",
        APPLICATION_JSON: "application/json"
    },
    HEADER_PROPS: {
        API_KEY: "api-key",
        CONTENT_TYPE: "Content-Type",
        PROFILE_KEY: "profile-key",
        ROUTE_KEY: "route-key",
    },
    REQ_TYPE: {
        GET: "get",
        POST: "post",
        PUT: "put",
        DELETE: "delete"
    },
    MESSAGES: {
        AGILITE_CLOSE: "Are you sure you want to close the Portal?"
    },
    FORMATS: {
        DATE: "YYYY-MM-DD"
    }
};

export default Enums;