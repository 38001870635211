const config = require('./config.json');

let hostName = window.location.hostname;
let protocol = window.location.protocol;

const Globals = {
    config,
    version: "0.0.1",
    releaseDate: "12th February 2019",
    apiUrl: config.deployType === "local" ? protocol + "//" + hostName + ":6010" : config.apiUrl,
    apiKey: config.apiKey
};

export default Globals;