import React from 'react';
import { Layout} from 'antd';
import MemoryStore from '../../utils/memory-store';

const { Header } = Layout;

class CoreToolbar extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      language: props.language
    };
  }

  render(){
    return (
      <div>
        <Layout>
          <Header style={{backgroundColor: this.props.theme.primary, color: "white", fontSize: "16pt", height: "50px"}}>
            <div className="headerContents">
              {MemoryStore.survey[this.props.language].labelPortalTitle}
            </div>  
          </Header>
        </Layout>
      </div>
    )
  }
}

export default CoreToolbar;