import React from 'react';
import { Row, Col } from 'antd';
import MemoryStore from '../../utils/memory-store';

class SurveyOutro extends React.Component {
    constructor(props) {
      super(props);
  
      this.state = {
        language: props.language
      };
    }
  
    render(){
      return (
        <div>
            <Row type="flex" justify="center">
            <Col xs={24} sm={16} md={12} lg={8}>
                <center>
                <h1>{MemoryStore.survey[this.state.language].outroTitle}</h1>
                <h3>{MemoryStore.survey[this.state.language].outroMessage}</h3>
                </center>
            </Col>
            </Row>
        </div> 
      )
    }
}

export default SurveyOutro;
