import { connect } from 'react-redux'
import SurveyQuestions from '../components/survey-questions'
import {switchLanguage, completeSurvey} from '../core-actions';
import {validateSurvey, submitSurvey} from '../core-utils';

const mapStateToProps = (state) => {
    return {
        language: state.core.language,
        theme: state.core.theme
    }
}

const mapDispatchToProps = (dispatch) => {
    return {
        switchLanguage: (language) => {
            switchLanguage(dispatch, language)
        },
        validateSurvey: (language, callback) => {
            validateSurvey(language, callback)
        },
        submitSurvey: (callback) => {
            submitSurvey(callback)
        },
        completeSurvey: () => {
            completeSurvey(dispatch)
        }
    }
}

const SurveyQuestionsContainer = connect(
    mapStateToProps,
    mapDispatchToProps
)(SurveyQuestions)

export default SurveyQuestionsContainer