export const actions = {
    SWITCH_LANGUAGE: 'SWITCH_LANGUAGE',
    SWITCH_PAGE: 'SWITCH_PAGE',
    COMPLETE_SURVEY: 'COMPLETE_SURVEY'
};

export const switchLanguage = (dispatch, language) => {
    dispatch({type: actions.SWITCH_LANGUAGE, language});
};

export const switchPage = (dispatch, page) => {
    dispatch({type: actions.SWITCH_PAGE, page});
};

export const completeSurvey = (dispatch) => {
    dispatch({type: actions.COMPLETE_SURVEY});
};