import React from 'react';
import ReactDOM from 'react-dom';
import { Provider } from 'react-redux';
import { createStore, applyMiddleware, combineReducers } from 'redux';
import thunk from 'redux-thunk';

import CoreAppContainer from './core/containers/core-app-container';
import * as serviceWorker from './serviceWorker';

import 'antd/dist/antd.css';
import './index.css';

/*
    //MODULECONDITION: Custom Components Import
*/
import core from './core/core-reducer';

//MODULECONDITION: Setup Reducers
const indexReducerWrapper = combineReducers({
    core
});

//Create Store with Middleware
const createStoreWithMiddleware = applyMiddleware(thunk)(createStore);

let store = createStoreWithMiddleware(indexReducerWrapper,
    window.__REDUX_DEVTOOLS_EXTENSION__ && process.env.NODE_ENV !== "production" ? window.__REDUX_DEVTOOLS_EXTENSION__() : undefined
);

ReactDOM.render(
    <Provider store={store}>
        <CoreAppContainer  />
    </Provider>,
    document.getElementById('root')
  );

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: http://bit.ly/CRA-PWA
serviceWorker.unregister();
