import { connect } from 'react-redux'
import CoreApp from '../components/core-app'

const mapStateToProps = (state) => {
    return {
        page: state.core.page
    }
}

const CoreAppContainer = connect(
    mapStateToProps
)(CoreApp)

export default CoreAppContainer