import Axios from 'axios';

import MemoryStore from '../utils/memory-store';
import Globals from '../utils/globals';
import Enums from '../utils/enums';
import validate from 'validate.js';

/*
  PUBLIC FUNCTIONS
*/
export const validateSurvey = (language, callback) => {
    let data = null;
    let constraints = {};
    let validMessage = null;

    //First we have to validate the data
    data = MemoryStore.survey;

    //Q1
    if(data.q1.value1 === "" || data.q1.value2 === "")
        return callback(`${MemoryStore.survey[language].validation1} 1`);

    //Q2
    if(!data.q2.value1 || data.q2.value1 === "")
        return callback(`${MemoryStore.survey[language].validation1} 2`);

    //Q3
    if(data.q3.value1 === "")
        return callback(`${MemoryStore.survey[language].validation1} 3`);

    //Q4
    if(data.q4.value1 === "" || data.q4.value2 === "")
        return callback(`${MemoryStore.survey[language].validation1} 4`);

    //Q5
    if(data.q5.value1 === "")
        return callback(`${MemoryStore.survey[language].validation1} 5`);

    //Q6
    if(data.q6.value1 === "")
        return callback(`${MemoryStore.survey[language].validation1} 6`);

    //Q7
    if(data.q7.value1 === "" || data.q7.value2 === "" || data.q7.value3 === "" || data.q7.value4 === "")
        return callback(`${MemoryStore.survey[language].validation1} 7`);

    //Validate Email Value
    constraints = { tempValue: { email: true } };
    validMessage = validate({ tempValue: data.q7.value3 }, constraints);

    if (validMessage)
        return callback (`${MemoryStore.survey[language].validation2} 7`);

    //Validate Telephone Number
    if(data.q7.value4.indexOf("_") > -1)
        return callback (`${MemoryStore.survey[language].validation3} 7`);

    //Validation Question 4
    if((parseFloat(data.q4.value1) + parseFloat(data.q4.value2)) !== 100)
        return callback (`${MemoryStore.survey[language].validation4}`);

    //If we get here, validation was successful
    return callback(null);
};

export const submitSurvey = (callback) => {
    let data = JSON.parse(JSON.stringify(MemoryStore.survey));

    //Format final result
    data.dateCreated = new Date();
    data.q7.value3 = data.q7.value3.toLowerCase();
    data.userAgent = window.navigator.userAgent;

    delete data.en;
    delete data.af;

    let config = {
        headers: {},
        method: Enums.REQ_TYPE.POST,
        url: `${Globals.apiUrl}/connectors/execute`,
        data: {
            data: JSON.stringify(data)
        }
    };

    config.headers[Enums.HEADER_PROPS.CONTENT_TYPE] = Enums.VALUES_STRINGS.APPLICATION_JSON;
    config.headers[Enums.HEADER_PROPS.API_KEY] = Globals.config.apiKey;
    config.headers[Enums.HEADER_PROPS.PROFILE_KEY] = Globals.config.profileKey;
    config.headers[Enums.HEADER_PROPS.ROUTE_KEY] = Globals.config.routeKey;

    Axios.request(config)
    .then(function (response) {
        return callback(null);
    })
    .catch(function (error) {
        console.log(error);
        return callback(error);
    });
};