import coreState from './core-state';
import {actions} from './core-actions';
import Enums from '../utils/enums';

const core = (state = coreState, action) => {
    switch (action.type) {
        case actions.SWITCH_LANGUAGE:
            return Object.assign({}, state, {
                language: action.language
            });
        case actions.SWITCH_PAGE:
            return Object.assign({}, state, {
                page: action.page
            });
        case actions.COMPLETE_SURVEY:
            return Object.assign({}, state, {
                page: Enums.PAGE_IDS.OUTRO
            });
        default:
            return state
    }
}

export default core;