import React from 'react';
import {Spin, Radio, Popconfirm,message, Row, Col, Card, Button, Form, Icon, InputNumber, DatePicker, Select, Input} from 'antd';
import MaskedInput from 'react-text-mask'
import emailMask from 'text-mask-addons/dist/emailMask'
import moment from 'moment';

import MemoryStore from '../../utils/memory-store';
import Enums from '../../utils/enums';

class SurveyMain extends React.Component {
  constructor(props){
		super(props);

		this.state = {
      language: props.language,
      surveyData: MemoryStore.survey[props.language],
      loading: false,
      disabled: false
    };
  
    this.switchLanguage = this.switchLanguage.bind(this);
    this.submitSurvey = this.submitSurvey.bind(this);
    this.onFieldChange = this.onFieldChange.bind(this);
  }
  
  componentWillReceiveProps(nextProps){
    let surveyData = MemoryStore.survey[nextProps.language];
    let language = nextProps.language;

    this.setState({language, surveyData});
  }

  switchLanguage(value){
		this.props.switchLanguage(value);
  }
  
	submitSurvey(){
    let tmpThis = this;

    tmpThis.props.validateSurvey(tmpThis.props.language, function(validation){
      if(validation){
        //Validation Failed
        message.error(validation);
      }else{
        tmpThis.setState({loading: true, disabled: true});

        tmpThis.props.submitSurvey(function(err){
          if(err){
            //HTTP Error Occurred
            message.error(err);
            tmpThis.setState({loading: false, disabled: false});
          }else{
            tmpThis.props.completeSurvey();
          }
        });
      }
    });
	}

	onFieldChange(question, field, value){
    if(value === undefined)
      value = "";

    MemoryStore.survey[question][field] = value;
	}

  render(){
    function disabledDate(current) {
      // Can not select days after today
      return current && current > moment().endOf('day');
    }

    return(
      <div style={{ background: '#fff' }}>
      <Row gutter={24} type="flex" justify="center">
          <Col xs={24} sm={24} md={16} lg={16}>
            <Card title={this.state.surveyData.labelWelcome} bordered={true}>
              <div>
      
                  <div style={{clear: "left"}}>
                    <div style={{width: 120, float: "left", marginTop: 8}}>Select Language:</div>
                    <Form.Item>
                    <Radio.Group
                      defaultValue={this.state.language}
                      disabled={this.state.disabled}
                      buttonStyle="solid"
                      style={{float: "left"}}
                      onChange={(e) => {
                        this.switchLanguage(e.target.value);
                      }}
                    >
                      <Radio.Button value={Enums.VALUES_STRINGS.AFRIKAANS_VALUE}>{Enums.VALUES_STRINGS.AFRIKAANS_LABEL}</Radio.Button>
                      <Radio.Button value={Enums.VALUES_STRINGS.ENGLISH_VALUE}>{Enums.VALUES_STRINGS.ENGLISH_LABEL}</Radio.Button>
                    </Radio.Group>
                    </Form.Item>
                  </div>

                <strong >{this.state.surveyData.q1.label}</strong>
                <Form.Item style={{marginTop: 10}}>
                  <div style={{clear: "left"}}>
                    <div style={{width: 50, float: "left"}}>{this.state.surveyData.q1.value1.prefix}</div>
                    <InputNumber
                      min={0}
                      required={true}
                      disabled={this.state.disabled}
                      style={{float: "left"}}
                      onChange={(e) => {
                        this.onFieldChange("q1", "value1", e)
                      }}
                    />
                    <div style={{marginLeft: 5, float: "left"}}>{this.state.surveyData.q1.value1.suffix}</div>
                  </div>
                  <div style={{clear: "left"}}>
                  <div style={{width: 50, float: "left"}}>{this.state.surveyData.q1.value2.prefix}</div>
                    <InputNumber
                      min={0}
                      required={true}
                      disabled={this.state.disabled}
                      style={{float: "left"}}
                      onChange={(e) => {
                        this.onFieldChange("q1", "value2", e)
                      }}
                    />
                    <div style={{marginLeft: 5, float: "left"}}>{this.state.surveyData.q1.value2.suffix}</div>
                  </div>
                </Form.Item>

                <strong>{this.state.surveyData.q2.label}</strong>
                <Form.Item style={{marginTop: 10}}>
                  <div style={{clear: "left"}}>
                    <DatePicker
                      format={Enums.FORMATS.DATE}
                      size="default"
                      allowClear={true}
                      disabled={this.state.disabled}
                      placeholder={this.state.surveyData.labelSelectDate}
                      required={true}
                      disabledDate={disabledDate}
                      onChange={(e, value) => {
                        this.onFieldChange("q2", "value1", value)
                      }}
                    />
                  </div>
                </Form.Item>

                <strong>{this.state.surveyData.q3.label}</strong>
                <Form.Item style={{marginTop: 10}}>
                  <div style={{clear: "left"}}>
                    <InputNumber
                      min={0}
                      max={5000}
                      required={true}
                      disabled={this.state.disabled}
                      style={{float: "left"}}
                      onChange={(e) => {
                        this.onFieldChange("q3", "value1", e)
                      }}
                    />
                    <div style={{marginLeft: 5, float: "left"}}>{this.state.surveyData.q3.value1.suffix}</div>
                    <div style={{clear: "left"}}>{this.state.surveyData.q3.value1.example}</div>
                  </div>
                </Form.Item>

                <strong>{this.state.surveyData.q4.label}</strong>
                <Form.Item style={{marginTop: 10}}>
                  <div style={{clear: "left"}}>
                    <InputNumber
                      min={0}
                      max={100}
                      required={true}
                      disabled={this.state.disabled}
                      style={{float: "left"}}
                      onChange={(e) => {
                        this.onFieldChange("q4", "value1", e)
                      }}
                    />
                    <div style={{marginLeft: 5, float: "left"}}>{this.state.surveyData.q4.value1.suffix}</div>
                  </div>
                  <div style={{clear: "left"}}>
                    <InputNumber
                      min={0}
                      max={100}
                      required={true}
                      disabled={this.state.disabled}
                      style={{float: "left"}}
                      onChange={(e) => {
                        this.onFieldChange("q4", "value2", e)
                      }}
                    />
                    <div style={{marginLeft: 5, float: "left"}}>{this.state.surveyData.q4.value2.suffix}</div>
                  </div>
                </Form.Item>

                <strong className="line-height-fix">{this.state.surveyData.q5.label}</strong>
                <Form.Item style={{marginTop: 10}}>
                  <div style={{clear: "left"}}>
                    <InputNumber
                      min={0}
                      max={5000}
                      required={true}
                      disabled={this.state.disabled}
                      style={{float: "left"}}
                      onChange={(e) => {
                        this.onFieldChange("q5", "value1", e)
                      }}
                    />
                    <div className="ant-col-xs-8 ant-col-md-20" style={{marginLeft: 5, float: "left"}}>{this.state.surveyData.q5.value1.suffix}</div>
                  </div>
                </Form.Item>

                <strong>{this.state.surveyData.q6.label}</strong>
                <Form.Item style={{marginTop: 10}}>
                  <div style={{clear: "left"}}>
                    <Select
                      placeholder={this.state.surveyData.labelSelectProvince}
                      required={true}
                      disabled={this.state.disabled}
                      className="ant-col-xs-24 ant-col-md-12 ant-col-lg-8"
                      onChange={(e) => {
                        this.onFieldChange("q6", "value1", e)
                      }}
                    >
                      {this.state.surveyData.provinces.map(entry =>
                        <Select.Option key={entry.value} value={entry.value}>{entry.label}</Select.Option>
                      )}
                    </Select>
                  </div>
                </Form.Item>

                <strong>{this.state.surveyData.q7.label}</strong>
                <Form.Item style={{marginTop: 10}}>
                  <div style={{clear: "left"}}>
                    <div className="ant-col-xs-24 ant-col-md-8 ant-col-lg-5" style={{float: "left"}}>{this.state.surveyData.q7.value1.prefix}</div>
                    <Input
                      required={true}
                      disabled={this.state.disabled}
                      className="ant-col-xs-24 ant-col-md-12 ant-col-lg-8"
                      style={{float: "left", paddingLeft: 11}}
                      onChange={(e) => {
                        this.onFieldChange("q7", "value1", e.target.value)
                      }}
                    />
                  </div>
                  <div style={{clear: "left"}}>
                    <div className="ant-col-xs-24 ant-col-md-8 ant-col-lg-5" style={{float: "left"}}>{this.state.surveyData.q7.value2.prefix}</div>
                    <Input
                      required={true}
                      disabled={this.state.disabled}
                      className="ant-col-xs-24 ant-col-md-12 ant-col-lg-8"
                      style={{float: "left", paddingLeft: 11}}
                      onChange={(e) => {
                        this.onFieldChange("q7", "value2", e.target.value)
                      }}
                    />
                  </div>
                  <div style={{clear: "left"}}>
                    <div className="ant-col-xs-24 ant-col-md-8 ant-col-lg-5" style={{float: "left"}}>{this.state.surveyData.q7.value3.prefix}</div>
                    <MaskedInput
                      mask={emailMask}
                      className="ant-input ant-col-xs-24 ant-col-md-12 ant-col-lg-8"
                      placeholder="joe.blogs@acme.com"
                      guide={true}
                      required={true}
                      disabled={this.state.disabled}
                      style={{float: "left", paddingLeft: 11}}
                      onChange={(e) => {
                        this.onFieldChange("q7", "value3", e.target.value)
                      }}
                    />
                  </div>
                  <div style={{clear: "left"}}>
                    <div className="ant-col-xs-24 ant-col-md-8 ant-col-lg-5" style={{float: "left"}}>{this.state.surveyData.q7.value4.prefix}</div>
                    <MaskedInput
                      mask={['(', /[0-9]/, /\d/, /\d/, ')', ' ', /\d/, /\d/, /\d/, '-', /\d/, /\d/, /\d/, /\d/]}
                      className="ant-input ant-col-xs-24 ant-col-md-12 ant-col-lg-8"
                      placeholder="(111) 111 1111"
                      type="tel"
                      required={true}
                      disabled={this.state.disabled}
                      style={{float: "left", paddingLeft: 11}}
                      onChange={(e) => {
                        this.onFieldChange("q7", "value4", e.target.value)
                      }}
                    />
                  </div>
                </Form.Item>
              </div>   
              <center>
                {!this.state.disabled?
                  <Popconfirm
                    title={this.state.surveyData.labelConfirm}
                    onConfirm={this.submitSurvey}
                    okText={this.state.surveyData.labelYes}
                    cancelText={this.state.surveyData.labelNo}
                  >
                    <Button
                      type="default"
                      disabled={this.state.disabled}
                      style={{backgroundColor:"#67AD5B", color:"white", width: "auto"}}
                      onChange={(e) => {
                        e.preventDefault()
                      }}
                    >
                      {this.state.surveyData.btnFinish}
                      <Icon type="check" />
                    </Button> 
                  </Popconfirm>
                :
                  <Button
                    type="default"
                    disabled={this.state.disabled}
                    style={{backgroundColor:"#67AD5B", color:"white", width: "auto"}}
                    onChange={(e) => {
                      e.preventDefault()
                    }}
                  >
                    {this.state.surveyData.btnFinish}
                    <Icon type="check" />
                  </Button> 
                }
                <Spin spinning={this.state.loading} style={{marginLeft: 10}} delay={100} size="large" />
              </center>
            </Card>
          </Col>
        </Row>
      </div>
    )
  }
}

export default SurveyMain;