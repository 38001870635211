import { connect } from 'react-redux';
import CoreToolbar from '../components/core-toolbar';

const mapStateToProps = (state) => {
    return {
        appId: state.core.active,
        language: state.core.language,
        theme: state.core.theme
    }
}

const CoreToolbarContainer = connect(
    mapStateToProps,
    null
)(CoreToolbar)

export default CoreToolbarContainer;