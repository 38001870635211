const memoryStore = {
    apiKey: "",
    teamId: "",
    survey: {
        ipAddress:"",
        userAgent:"",
        q1:{
            value1: "",
            value2: ""
        },
        q2:{
            value1: null
        },
        q3:{
            value1: ""
        },
        q4:{
            value1: "",
            value2: ""
        },
        q5:{
            value1: ""
        },
        q6:{
            value1: ""
        },
        q7:{
            value1: "",
            value2: "",
            value3: "",
            value4: ""
        },
        en: {
            intro:{
                paragraphs: [
                    {
                        key: "1",
                        value: "Dear Producer.",
                    },
                    {
                        key: "2",
                        value: "The most important cornerstone for the effective functioning and accurate pricing in a free market is Reliable Transparent Information. It is the purpose of this survey to achieve this and I therefore urge all maize producers to please take part in the survey.",
                    },
                    {
                        key: "3",
                        value: "Knowledge of the surviving planted hectares will enable all producers to make an accurate informed decision.",
                    },
                    {
                        key: "4",
                        value: "Please provide your contact details as requested in the survey to enable our team to share the cumulative information simultaneously.",
                    },
                    {
                        key: "5",
                        value: "Please circulate to all your fellow producers.",
                    },
                    {
                        key: "6",
                        value: "Yours sincerely Petrus Claassen.",
                    }
                ]
            },
            q1:{
                label: "Q1: How many hectares have you planted in the current season?",
                value1:{
                    prefix: "Yellow",
                    suffix: "Ha"
                },
                value2:{
                    prefix: "White",
                    suffix: "Ha"
                }
            },
            q2:{
                label: "Q2: On which date did you do the majority of the planting?"
            },
            q3:{
                label: "Q3: What is the percentage planted this season in relation to the previous season?",
                value1:{
                    suffix: "% maize planted relative to previous season",
                    example: "(For example if you planted 1000 ha the previous season and 800 ha this season then the percentage is 80%)",
                }
            },
            q4:{
                label: "Q4: What is the state of your crop?",
                value1:{
                    suffix: "% have emerged and is looking fine."
                },
                value2:{
                    suffix: "% planted but didn't make it."
                }
            },
            q5:{
                label: "Q5: What percentage of your normal rainfall to date have you received this season?",
                value1:{
                    suffix: "% of normal rainfall."
                }
            },
            q6:{
                label: "Q6: Please select your Province."
            },
            q7:{
                label: "Q7: Please enter your contact details below to receive the survey results and to enable us to check the data if necessary.",
                value1:{
                    prefix: "Name:"
                },
                value2:{
                    prefix: "Surname:"
                },
                value3:{
                    prefix: "Email Address:"
                },
                value4:{
                    prefix: "Phone Number:"
                }
            },
            labelWelcome: "Welcome to the 2018/2019 South African Maize Survey",
            labelPortalTitle: "Maize Survey 2018/2019",
            labelConfirm: "Are you sure you are ready to submit this Survey?",
            validation1: "Please provide value(s) for question",
            validation2: "Please provide a valid Email for question",
            validation3: "Please provide a valid Phone Number for question",
            validation4: "The values combined need to total 100 for question 4",
            labelYes: "Yes",
            labelNo: "No",
            btnNext: "Next",
            btnFinish: "Finish",
            labelSelectDate: "-Select Date-",
            labelSelectProvince: "-Select Province-",
            labelSelect: "-Select-",
            outroTitle: "Survey Submitted Successfully",
            outroMessage: "Thank you. Your responses have been successfully submitted",
            provinces: [
                {
                    label: "Eastern Cape",
                    value: "EC"
                },
                {
                    label: "Free State",
                    value: "FS"
                },
                                {
                    label: "Gauteng",
                    value: "GP"
                },
                {
                    label: "Kwazulu-Natal",
                    value: "KZN"
                },
                {
                    label: "Limpopo",
                    value: "LP"
                },
                {
                    label: "Mpumalanga",
                    value: "MP"
                },
                {
                    label: "North West",
                    value: "NW"
                },
                {
                    label: "Nothern Cape",
                    value: "NC"
                },
                {
                    label: "Western Cape",
                    value: "WP"
                }
            ]
        },
        af: {
            intro:{
                paragraphs: [
                    {
                        key: "1",
                        value: "Geagte Produsent.",
                    },
                    {
                        key: "2",
                        value: "Die belangrikste hoeksteen vir die effektiewe funksionering en akkurate prysbepaling van ‘n vrye mark is Betroubare Deursigte Informasie. Die doel van hierdie opname is om dit te bereik en ek doen 'n beroep op alle mieleprodusente om asb deel te neem aan die opname.",
                    },
                    {
                        key: "3",
                        value: "Kennis van die suksesvolle hektare wat oorleef het sal al die produsente instaat stel om 'n ingeligte akkurate besluit te neem.",
                    },
                    {
                        key: "4",
                        value: "Verskaf asseblief u kontakbesonderhede soos gevra in die opname sodat die uitslag aan u gestuur kan word en data geverifieer kan word indien nodig.",
                    },
                    {
                        key: "5",
                        value: "Stuur ook asseblief die opname aan al u mede produsente.",
                    },
                    {
                        key: "6",
                        value: "Baie groete Petrus Claassen.",
                    }
                ]
            },
            q1:{
                label: "V1: Hoeveel hektaar het u hierdie seisoen geplant?",
                value1:{
                    prefix: "Geel",
                    suffix: "Ha"
                },
                value2:{
                    prefix: "Wit",
                    suffix: "Ha"
                }
            },
            q2:{
                label: "V2: Op watter datum het u die meerderheid van u aanplantings gedoen?"
            },
            q3:{
                label: "V3: Wat is u persentasie geplant relatief tot verlede seisoen?",
                value1:{
                    suffix: "% mielies geplant relatief tot verlede seisoen",
                    example: "(Voorbeeld, vorige seisoen 1000 ha, huidige seisoen 800 ha is die persentasie 80%)",
                }
            },
            q4:{
                label: "V4: Hoe lyk die Mielies?",
                value1:{
                    suffix: "% geplant het opgekom en lyk reg."
                },
                value2:{
                    suffix: "% geplant maar het dit nie gemaak nie."
                }
            },
            q5:{
                label: "V5: Wat is die persentasie van u reënval tot op datum vir die huidige seisoen relatief tot u normale reënval?",
                value1:{
                    suffix: "% van normale reënval."
                }
            },
            q6:{
                label: "V6: Kies asseblief u Provinsie."
            },
            q7:{
                label: "V7: Verskaf asseblief u kontakbesonderhede ten einde die opname resultate te kan ontvang en ons instaat te stel om data te kan nagaan waar van toepassing.",
                value1:{
                    prefix: "Naam:"
                },
                value2:{
                    prefix: "Van:"
                },
                value3:{
                    prefix: "Eposadres:"
                },
                value4:{
                    prefix: "Telefoonnommer:"
                }
            },
            labelWelcome: "Welkom by die 2018/2019 Suid Afrikaanse Mielie Opname",
            labelPortalTitle: "Mielie Opname 2018/2019",
            labelConfirm: "Is jy seker?",
            validation1: "Verskaf asseblief 'n antwoord vir vraag",
            validation2: "Verskaf asseblief 'n geldige Eposadres vir vraag",
            validation3: "Verskaf asseblief 'n geldige Telefoonnommer vir vraag",
            validation4: "Die antwoorde van vraag 4 moet tot op 100% optel",
            labelYes: "Ja",
            labelNo: "Nee",
            btnNext: "Volgende",
            btnFinish: "Klaar",
            labelSelectDate: "-Kies Datum-",
            labelSelectProvince: "-Kies Provinsie-",
            labelSelect: "-Kies-",
            outroTitle: "Opname Ontvang",
            outroMessage: "Baie dankie. U antwoorde is suksesvol ontvang",
            provinces: [
                {
                    label: "Oos-Kaap",
                    value: "EC"
                },
                {
                    label: "Vrystaat",
                    value: "FS"
                },
                                {
                    label: "Gauteng",
                    value: "GP"
                },
                {
                    label: "Kwazulu-Natal",
                    value: "KZN"
                },
                {
                    label: "Limpopo",
                    value: "LP"
                },
                {
                    label: "Mpumalanga",
                    value: "MP"
                },
                {
                    label: "Noord-Wes",
                    value: "NW"
                },
                {
                    label: "Noord-Kaap",
                    value: "NC"
                },
                {
                    label: "Wes-Kaap",
                    value: "WP"
                }
            ]
        }
    }
};

export default memoryStore;