import { connect } from 'react-redux'
import SurveyOutro from '../components/survey-outro'

const mapStateToProps = (state) => {
    return {
        language: state.core.language
    }
}

const SurveyOutroContainer = connect(
    mapStateToProps
)(SurveyOutro)

export default SurveyOutroContainer