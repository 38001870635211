import React from 'react';
import {Row, Col} from 'antd';

/*  
    Custom Components Import
*/
import CoreToolbarContainer from '../containers/core-toolbar-container';
import SurveyOutroContainer from '../containers/survey-outro-container';
import SurveyQuestionsContainer from '../containers/survey-questions-container';

import Enums from '../../utils/enums';

class CoreApp extends React.Component {
	componentDidMount(){
		window.addEventListener("beforeunload", function (e) {		
			(e || window.event).returnValue = Enums.MESSAGES.AGILITE_CLOSE; //Gecko + IE
			return Enums.MESSAGES.AGILITE_CLOSE; //Gecko + Webkit, Safari, Chrome etc.
		});	
	}

	render() {
		return (
			<div>
				<Row gutter={24} type="flex" justify="center">
					<Col xs={24} sm={24} md={24} lg={24}>
						<CoreToolbarContainer />
						<div style={{marginTop:20}}>
							{this.props.page === Enums.PAGE_IDS.QUESTIONS ? <SurveyQuestionsContainer /> : null}
							{this.props.page === Enums.PAGE_IDS.OUTRO ? <SurveyOutroContainer /> : null}
						</div>	
					</Col>
				</Row>
			</div>
		);
	}
}

export default CoreApp;